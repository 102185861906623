import { Component, OnInit } from '@angular/core';
import {UserService} from "../_services/user.service"
import { AlertService, AuthenticationService } from '../_services';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  fav:any = "assets/imgs/fav.png";
  constructor(public us:UserService, public authenticationService:AuthenticationService) { }
  
  test(){
    console.log(this.us.jobStore)
    console.log(this.us.currentUser.jobs)
  }

  changePass(){
    this.authenticationService.resetPassword();
    alert("A reset email has been sent to you")
  }

  
  logout(){
    this.authenticationService.logout(); 
  }

  ngOnInit() {
  }

}
