import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { User,Group} from '../_models';


import { UserService } from '../_services';
import { FormBuilder, FormGroup, Validators ,FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertService, AuthenticationService } from '../_services';
// import { faCoffee } from '@fortawesome/free-solid-svg-icons';

@Component({templateUrl: 'home.component.html'})
export class HomeComponent implements OnInit {
    // faCoffee = faCoffee;
    currentUser: User 
    users: User[] = [];
    group={}
    userGroups:Group[];
    fav:any = "assets/imgs/fav.png";
    newGroup: FormGroup;

    

    constructor(private formBuilder: FormBuilder,private userService: UserService,private authenticationService: AuthenticationService) {
        var parentThis = this;
        // this.currentUser= new User("","","","",[]);
        userService.getById(localStorage.getItem('user')).then(function(data){
             parentThis.currentUser = data
                // parentThis.userService.getUserGroups(parentThis.currentUser.groups).then(function(data){
                //     console.log(data)
                // })
                // parentThis.userGroups = parentThis.userService.getUserGroups(parentThis.currentUser.groups)
                
            })
    }

    ngOnInit() {
        
        // this.loadAllUsers();
        // this.loadAllGroups();
       
    }

    doStuff(){
        // this.createGroup("Jons Group","test desc")
    }

    logout(){
        this.authenticationService.logout(); 
    }

    // deleteUser(id: number) {
    //     this.userService.delete(id).pipe(first()).subscribe(() => { 
    //         this.loadAllUsers() 
    //     });
    // }
    
    

    // private loadAllGroups() {
    //     this.userService.getGroup("5").then((data) => { this.group = data})
    // }

    private loadAllUsers() {
        this.userService.getAll().pipe(first()).subscribe(users => { 
            this.users = users; 
        });
    }
}