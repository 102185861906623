
export class Job {
    jid: string;
    jobName: string;
    full: boolean;
    desc: string;
    date;
    startTime;
    endTime;
    crewLimit: number;
    crew:string[];
    offeredCrew:string[];

}