import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore'
import * as firebase from 'firebase/app'
import { User, Group, Job } from '../_models';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { formGroupNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';
import { prepareSyntheticListenerFunctionName } from '@angular/compiler/src/render3/util';
// import { ConsoleReporter } from 'jasmine';
// import * as firebase from 'firebase';

@Injectable()
export class UserService {
    private userID: string = localStorage.getItem("user")
    subscription;
    currentUser: User;
    currentUserGroups: Group[];
    userStore = {};
    groupStore = {};
    jobStore = {};
    dropDownList;// = [];

    constructor(private http: HttpClient, public afs: AngularFirestore) {
        var parentThis = this;
        if(this.userID!=null){
        this.subscription = afs.collection('user').doc(this.userID).valueChanges().subscribe(function (doc: User) {
            parentThis.currentUser = doc
            
            parentThis.getUserGroups(parentThis.currentUser.groups)
            parentThis.getUserJobs(parentThis.currentUser.jobs)
            parentThis.getUserJobs(parentThis.currentUser.jobsAdmin)
        }, (err) => { console.log("problem getting current User : ", err) })
    }
    }


    outGroups() {
        // console.log(this.groupStore)
        console.log(this.userStore)
    }

    acceptJob(jid) {
        var jobRef = this.afs.collection('jobs').doc(jid)
        var uid = this.currentUser.uid
        var parentThis =this;
        if (this.jobStore[jid].full != true) {
            jobRef.ref.get().then(function (doc) {
                if (doc.exists) {
                    var tempArray = doc.data().crew
                    if (tempArray.indexOf(uid) == -1) {
                        tempArray.push(uid)
                        // console.log(tempArray)
                        if(tempArray.length == parentThis.jobStore[jid].crewLimit){
                            jobRef.ref.update({ "crew": tempArray,
                        "full":true })
                        }else{
                            jobRef.ref.update({ "crew": tempArray })
                        }
                        // userRef.update({"groups":firebase.FieldValue.arrayUnion("")})
                        
                        
                        console.log("added crew")
                    }
                }
            })
        }
    }

    declineJob(jid) {
        var jobRef = this.afs.collection('jobs').doc(jid)
        var uid = this.currentUser.uid
        var parentThis =this;
        jobRef.ref.get().then(function (doc) {
            if (doc.exists) {
                var tempArray = doc.data().crew
                if (tempArray.indexOf(uid) != -1) {
                    tempArray.splice(tempArray.indexOf(uid), 1)
                    // tempArray.push(uid)
                    if (parentThis.jobStore[jid].full == true) {
                        jobRef.ref.update({ "crew": tempArray,
                    "full":false })
                    }else{
                        jobRef.ref.update({ "crew": tempArray })
                    }
                    
                    console.log("removed crew")
                }
            }
        })
    }


    newJob(job: Job) {
        var parentThis = this;
        var jobRef = this.afs.collection('jobs')
        var data = {
            jobName: job.jobName,
            desc: job.desc,
            offeredCrew: job.offeredCrew,
            crewLimit: job.crewLimit,
            startTime: job.startTime,
            endTime: job.endTime,
            date: job.date,
            crew: [],
            full: false

        }
        jobRef.add(data).then(function (response) {
            jobRef.doc(response.id).ref.update({ jid: response.id })
            for (var i in job.offeredCrew) {
                console.log(i)

                //Add to admin list
                var currentUserRef = parentThis.afs.collection('user').doc(parentThis.currentUser.uid)
                currentUserRef.ref.get().then(function (doc) {
                    var userRefLocal = userRef
                    if (doc.exists) {
                        console.log(doc.data().jobsAdmin)
                        var tempArray = doc.data().jobsAdmin
                        if (tempArray.indexOf(response.id) == -1) {
                            tempArray.push(response.id)
                            parentThis.afs.collection('user').doc(doc.id).update({ "jobsAdmin": tempArray })
                        }
                        // userRef.update({groups:doc.data().groups})
                        return {}
                    } else {
                        console.log("Cant find user");
                        return {};
                    }
                }).catch(function (error) {
                    console.log("Error getting group:", error);
                    return {};
                });


                var userRef = parentThis.afs.collection('user').doc(job.offeredCrew[i])
                // console.log(member)
                console.log(job.offeredCrew[i])
                userRef.ref.get().then(function (doc) {
                    var userRefLocal = userRef
                    if (doc.exists) {
                        console.log(doc.data().jobs)
                        var tempArray = doc.data().jobs
                        if (tempArray.indexOf(response.id) == -1) {
                            tempArray.push(response.id)
                            // console.log(tempArray)
                            // userRef.update({"groups":firebase.FieldValue.arrayUnion("")})
                            parentThis.afs.collection('user').doc(doc.id).update({ "jobs": tempArray })
                        }
                        // userRef.update({groups:doc.data().groups})
                        return {}
                    } else {
                        console.log("Cant find user");
                        return {};
                    }
                }).catch(function (error) {
                    console.log("Error getting group:", error);
                    return {};
                });
            }

        })
    }

    setUserID(uid: string) {
        this.userID = uid
        console.log("SETTTTTTTTTTTTTTTTTTTTT UID")
        if(this.subscription){
            this.subscription.unsubscribe();
        }
        var parentThis = this;
        this.subscription = this.afs.collection('user').doc(this.userID).valueChanges().subscribe(function (doc: User) {
            console.log(doc)
            parentThis.currentUser = doc
            
            parentThis.getUserGroups(parentThis.currentUser.groups)
            parentThis.getUserJobs(parentThis.currentUser.jobs)
            parentThis.getUserJobs(parentThis.currentUser.jobsAdmin)
        }, (err) => { console.log("probleme : ", err) })
    }

    //sort the current users jobs based on date
    sortJobs(){
        var parentThis = this;
        try{
            this.currentUser.jobsAdmin.sort(function(a,b){return parentThis.jobStore[b].date.obj.getTime() - parentThis.jobStore[a].date.obj.getTime()});
            this.currentUser.jobs.sort(function(a,b){return parentThis.jobStore[b].date.obj.getTime() - parentThis.jobStore[a].date.obj.getTime()});
        }catch(Error){
        }
    }

    

    genDropDownList() {
        this.dropDownList = []
        for (var i = 0; i < this.currentUser.groups.length; i++) {
            this.dropDownList.push({ item_id: i + 1, item_text: this.groupStore[this.currentUser.groups[i]].groupName })
        }
    }

    editGroup(name,desc,gid){
        var parentThis = this;
        var groupRef= parentThis.afs.collection('groups').doc(gid)
        groupRef.ref.update({groupName:name,desc:desc})
    }

    editJob(name,desc,date,crewLimit,jid,startTime,endTime){
        var parentThis = this;
        var groupRef= parentThis.afs.collection('jobs').doc(jid)
        groupRef.ref.update({jobName:name,desc:desc,date:date,startTime:startTime,endTime:endTime,crewLimit:crewLimit})
    }


    addUser(gid, email) {
        var parentThis = this;
        var emailRef = this.afs.collection('emails').doc(email);
        console.log(gid)

        emailRef.ref.get().then(function (doc) {
            if (doc.exists) {
                console.log(doc.data().uid)
                var userID = doc.data().uid
                console.log(gid)
                var groupRef = parentThis.afs.collection('groups').doc(gid)
                groupRef.ref.get().then(function (doc) {
                    if (doc.exists) {
                        var tempArray = doc.data().members
                        if (tempArray.indexOf(userID) == -1) {
                            tempArray.push(userID)
                            // console.log(tempArray)
                            // userRef.update({"groups":firebase.FieldValue.arrayUnion("")})
                            groupRef.ref.update({ "members": tempArray })
                            console.log("added user")
                        }
                    }
                })

            } else {
                return null
            }
        })
    }

    removeUser(uid, gid) {
        console.log(uid)
        var groupRef = this.afs.collection('groups').doc(gid)
        groupRef.ref.get().then(function (doc) {
            if (doc.exists) {
                var tempArray = doc.data().members
                if (tempArray.indexOf(uid) != -1) {
                    tempArray.splice(tempArray.indexOf(uid), 1)
                    console.log(tempArray)
                    groupRef.ref.update({ "members": tempArray })
                    console.log("Removed User")
                }
            }
        })

    }

    promoteDemoteUser(uid, gid) {
        console.log(uid)
        console.log(this.userStore)
        console.log(this.groupStore[gid].admins.indexOf(uid))
        console.log(this.groupStore[gid].admins)
        var groupRef = this.afs.collection('groups').doc(gid)
        var userRef = this.afs.collection('user').doc(uid)
        if (this.groupStore[gid].admins.indexOf(uid) != -1) {
            console.log(this.groupStore[gid].admins.indexOf(uid))
            this.groupStore[gid].admins.splice(this.groupStore[gid].admins.indexOf(uid), 1)
            this.userStore[uid].groups.splice(this.userStore[uid].groups.indexOf(gid), 1)
        } else {
            this.groupStore[gid].admins.push(uid)
            this.userStore[uid].groups.push(gid)
        }
        groupRef.ref.update({ "admins": this.groupStore[gid].admins })
        userRef.ref.update({ "groups": this.userStore[uid].groups })
    }



    getCurrentUser() {
        var parentThis = this
        this.getById(this.userID).then(function (data) {
            parentThis.currentUser = data
        })
    }



    getGroup(id: string) {
        var groupRef = this.afs.collection('groups').doc(id);
        return groupRef.valueChanges().subscribe(function (doc) {
            return doc

        }, (err) => { console.log("probleme : ", err) })
    }

    // getGroup(id: string) {
    //     var groupRef = this.afs.collection('groups').doc(id);
    //     return groupRef.ref.get().then(function (doc) {
    //         if (doc.exists) {
    //             return doc.data()
    //         } else {
    //             console.log("No such group!");
    //             return {};
    //         }
    //     }).catch(function (error) {
    //         console.log("Error getting group:", error);
    //         return {};
    //     });
    // }

    createGroup(groupName, uid, desc: string) {
        var parentThis = this;
        var groupRef = this.afs.collection('groups')
        const data = {
            groupName: groupName,
            members: [uid],
            admins: [uid],
            jobs: [],
            desc: desc
        };
        groupRef.add(data).then(function (response) {
            groupRef.doc(response.id).ref.update({ gid: response.id })
            var userRef = parentThis.afs.collection('user').doc(uid)

            userRef.ref.get().then(function (doc) {
                if (doc.exists) {
                    console.log(doc.data().groups)
                    var tempArray = doc.data().groups
                    if (tempArray.indexOf(response.id) == -1) {
                        tempArray.push(response.id)
                        // console.log(tempArray)
                        // userRef.update({"groups":firebase.FieldValue.arrayUnion("")})
                        userRef.update({ "groups": tempArray })
                    }
                    // userRef.update({groups:doc.data().groups})
                    return {}
                } else {
                    console.log("Cant find user");
                    return {};
                }
            }).catch(function (error) {
                console.log("Error getting group:", error);
                return {};
            });
        })
    }

    getAll() {
        return this.http.get<User[]>(`/users`);
    }



    getUserGroups(userGroups: string[]) {
        var parentThis = this;
        console.log(userGroups)
        for (var i = 0; i < userGroups.length; i++) {
            if (!(userGroups[i] in this.groupStore)) {
                this.afs.collection('groups').doc(userGroups[i]).valueChanges().subscribe(function (doc: Group) {
                    console.log(doc)
                    parentThis.groupStore[doc.gid] = doc
                    parentThis.getUserArray(doc.members)
                    parentThis.genDropDownList()
                }, (err) => { console.log("probleme : ", err) })
            }
        }

    }

    getUserJobs(userJobs: string[]) {
        var parentThis = this;
        // console.log(userGroups)
        for (var i = 0; i < userJobs.length; i++) {
            if (!(userJobs[i] in this.jobStore)) {
                this.afs.collection('jobs').doc(userJobs[i]).valueChanges().subscribe(function (doc: Job) {
                    parentThis.jobStore[doc.jid] = doc
                    // console.log( doc.date.year,doc.date.month,doc.date.year)
                    parentThis.jobStore[doc.jid].date["obj"]=new Date(doc.date.year,doc.date.month-1,doc.date.day);
                    parentThis.getUserArray(doc.offeredCrew)
                    parentThis.sortJobs();
                }, (err) => { console.log("problem getting user jobs : ", err) })
            }
        }
        //parentThis.sortJobs();

    }


    getUserArray(members) {
        var parentThis = this;
        var userArray: User[] = []
        for (var i = 0; i < members.length; i++) {
            var g = members[i]
            if (!(g in this.userStore)) {
                var userRef = this.afs.collection('user').doc(g);
                var temp = userRef.ref.get().then(function (doc) {
                    if (doc.exists) {
                        var user: User = new User();
                        user.uid = doc.data().uid
                        user.firstName = doc.data().firstName
                        user.lastName = doc.data().lastName
                        user.email = doc.data().email
                        user.groups = doc.data().groups
                        parentThis.userStore[user.uid] = user
                    } else {
                        console.log("Cant find group2");
                    }
                });
            }
        }
    }

    getById(id: string) {
        var user: User = new User();
        console.log("id: " + id)
        var userRef = this.afs.collection('user').doc(id);
        return userRef.ref.get().then(function (doc) {
            if (doc.exists) {
                var tempArray: string[] = doc.data().groups
                user.email = doc.data().email;
                user.firstName = doc.data().firstName;
                user.lastName = doc.data().lastName;
                user.uid = doc.data().uid;
                user.groups = doc.data().groups;
                user.jobs = doc.data().jobs;
                return user
            } else {
                console.log("No such document!");
                return user;
            }
        }).catch(function (error) {
            console.log("Error getting document:", error);
            return user;
        });
    }

    // register(user: User) {
    //     return this.http.post(`/users/register`, user);
    // }

    // update(user: User) {
    //     return this.http.put(`/users/` + user.id, user);
    // }

    // delete (id: number) {
    //     return this.http.delete(`/users/` + id);
    // }
}