import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { first } from 'rxjs/operators';




@Injectable()
export class AuthGuard implements CanActivate {
    public logged : boolean;
    constructor(private router: Router, public afAuth: AngularFireAuth) {
        var parentThis=this;
        var log;
        // afAuth.auth.setPersistence("local")
        console.log(this.afAuth.idToken)

        this.afAuth.auth.onAuthStateChanged(function(user) {
            console.log("statechange: "+user)
            if (user) {
                console.log("statechange2: "+true)
                parentThis.logged = true
            } else {
                console.log("statechange2: "+false)
                parentThis.logged = false;
            }
          });

          
     }
    
     isLoggedIn() {
        return this.afAuth.authState.pipe(first()).toPromise();
     }

     async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        var parentThis =this;
        console.log(this.afAuth.authState)
        if (typeof this.logged === 'undefined'){
            const user = await this.isLoggedIn()
            if(user){
                console.log("logged: "+true)
                this.logged=true;
                return true;
            }else{
                this.logged=false;
                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
                return false;
            }
            
        }else{

        if (this.logged){
            console.log("logged: "+true)
            return true;
        }else{
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
            return false;
        }    
    }
        // not logged in so redirect to login page with the return url
        
    }

    // canActivate(): Observable<boolean> {
    //     return this.afAuth.authState
    //       .take(1)
    //       .map(authState => !!authState)
    //       .do(auth => !auth ? this.router.navigate(['/login']) : true);
    //   }

    // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    //     return this.afAuth.authState.map((auth) =>  {
    //         if(auth == null) {
    //           this.router.navigate(['/']);
    //           return false;
    //         } else {
    //           return true;
    //         }
    //       });
    // }

}