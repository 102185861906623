import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { GroupsComponent } from './groups/groups.component';

import { RegisterComponent } from './register';
import { AuthGuard } from './_guards';
import { Profile } from 'selenium-webdriver/firefox';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { JobsComponent } from './jobs/jobs.component';
import { AdminComponent } from './admin/admin.component';

const appRoutes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] , children: [
    ]},
    
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'groups', component:GroupsComponent,canActivate: [AuthGuard] },
    { path: 'me', component:UserProfileComponent,canActivate: [AuthGuard]},
    { path: 'jobs', component:JobsComponent,canActivate: [AuthGuard]},
    { path: 'admin', component:AdminComponent,canActivate: [AuthGuard]},
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);