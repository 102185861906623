import { User } from "./user";

export class Group {
    gid: string;
    groupName: string;
    members: string[];
    membersArray: User[];
    admins: string[];
    jobs: string[];
    desc: string;

}