import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services';
import { User, Group } from '../_models';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as $ from 'jquery';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})


export class GroupsComponent implements OnInit {
  newGroup: FormGroup;
  addUserForm: FormGroup;
  editGroupForm: FormGroup;
  constructor(private formBuilder: FormBuilder, public userService: UserService) { }

  groupID: string;

  ngOnInit() {
    this.newGroup = this.formBuilder.group({
      groupName: ['', Validators.required],
      groupDesc: ['']
    });

    this.addUserForm = this.formBuilder.group({
      email: [''],
      picker:['']
    });

    this.editGroupForm = this.formBuilder.group({
      name: [''],
      desc:['']
    });
  }
  get f() { return this.newGroup.controls; } //helper function
  get g() { return this.addUserForm.controls; } //helper function
  get h() { return this.editGroupForm.controls; } //helper function

  createGroup() {
    if (this.newGroup.invalid) {
      return;
    }
    this.userService.createGroup(this.f.groupName.value, this.userService.currentUser.uid, this.f.groupDesc.value);
  }

  deleteGroup(gid: string) {

  }

  promote(uid,gid){
    this.userService.promoteDemoteUser(uid,gid)
  }
  editGroup(){
    var name = this.h.name.value
    var desc = this.h.desc.value
    console.log(name,desc);
    this.userService.editGroup(name,desc,this.groupID);
  }

  editGroupSetup(gid) {
    this.groupID = gid
    this.h.name.setValue(this.userService.groupStore[gid].groupName);
    this.h.desc.setValue(this.userService.groupStore[gid].desc);
  }


  addUserMain() {
    
    if (this.addUserForm.invalid) {
      return;
    }
    var mail;
    console.log(this.g.email.value)
    console.log(this.groupID)
    if(this.g.picker.value==""){
      if(this.g.email.value==""){
        return
      }
      else{
        mail = this.g.email.value
      }
    }
    else{
      mail = this.g.picker.value
    }
    this.userService.addUser(this.groupID,mail)
    // this.userService.createGroup(this.f.groupName.value, , this.f.groupDesc.value);
  }

  removeUser(uid,gid){

    this.userService.removeUser(uid,gid)
  }

 

  addUser(gid) {
    this.groupID = gid
  }

}
