import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services';
import { User, Group, Job } from '../_models';
import { FormBuilder, FormGroup, Validators ,FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimeoutError } from 'rxjs';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  jobID;
  dropdownList = [];
  selectedItems = [];
  userList = [];
  dropdownSettings = {};
  time; 
  time2; 
  time3; 
  time4; 
  time5;
  time6;
  spinners = false;
  newJob: FormGroup;
  editJobForm:FormGroup;
  copyJobForm:FormGroup;
  date;
  today;
  constructor(public userService: UserService,private formBuilder: FormBuilder) {
    this.today=new Date();
    
  }

  

  ngOnInit() 
    {
      this.newJob = this.formBuilder.group({
        jobName: ['', Validators.required],
        jobDesc: [''],
        crewLimit: [1],
        dateForm:[]

      });

      this.editJobForm = this.formBuilder.group({
        jobName: ['', Validators.required],
        jobDesc: [''],
        crewLimit: [1],
        dateForm:[]
      })

      this.copyJobForm = this.formBuilder.group({
        jobName: ['', Validators.required],
        jobDesc: [''],
        crewLimit: [1],
        dateForm:[]
      })

     

      
      console.log(this.dropdownList)
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: false,
        enableCheckAll: false
      };
    }

    editJob(){
      var name = this.h.jobName.value;
      var desc = this.h.jobDesc.value;
      var date = this.h.dateForm.value;
      var crewLimit = this.h.crewLimit.value;
      this.userService.editJob(name,desc,date,crewLimit,this.jobID,this.time3.hour +":"+ this.time3.minute,this.time4.hour +":"+ this.time4.minute);
    }
    
    getUserList(){
      var parentThis = this
      this.userList=[]
      for(var i in this.selectedItems){
        console.log(this.selectedItems[i].item_id-1)
        this.userList= this.userList.concat(this.userService.groupStore[this.userService.currentUser.groups[this.selectedItems[i].item_id-1]].members);   //[...new Set([...this.userList, ...this.userService.currentUser.groups)];
        this.userList = this.userList.filter(function (item, pos) {return parentThis.userList.indexOf(item) == pos});
      }
    }

    get h() { return this.editJobForm.controls; } //helper function
    get g() { return this.copyJobForm.controls; } //helper function

    editJobSetup(jid) {
      this.jobID = jid
      console.log(this.userService.jobStore[jid])
      this.h.jobName.setValue(this.userService.jobStore[jid].jobName);
      this.h.jobDesc.setValue(this.userService.jobStore[jid].desc);
      this.h.dateForm.setValue(this.userService.jobStore[jid].date);
      this.h.crewLimit.setValue(this.userService.jobStore[jid].crewLimit);
      this.time3 = {hour: Number(this.userService.jobStore[jid].startTime.split(":")[0]), minute: Number(this.userService.jobStore[jid].startTime.split(":")[1])}
      this.time4 = {hour: Number(this.userService.jobStore[jid].endTime.split(":")[0]), minute: Number(this.userService.jobStore[jid].endTime.split(":")[1])}      
    }

    copyJobSetup(jid) {
      this.jobID = jid
      console.log(this.userService.jobStore[jid])
      this.g.jobName.setValue(this.userService.jobStore[jid].jobName);
      this.g.jobDesc.setValue(this.userService.jobStore[jid].desc);
      this.g.dateForm.setValue(this.userService.jobStore[jid].date);
      this.g.crewLimit.setValue(this.userService.jobStore[jid].crewLimit);
      this.time5 = {hour: Number(this.userService.jobStore[jid].startTime.split(":")[0]), minute: Number(this.userService.jobStore[jid].startTime.split(":")[1])}
      this.time6 = {hour: Number(this.userService.jobStore[jid].endTime.split(":")[0]), minute: Number(this.userService.jobStore[jid].endTime.split(":")[1])}      
    }


    copyJob(){
      var users =[]
      var job:Job = new Job();
      job.jobName = this.g.jobName.value
      job.desc = this.g.jobDesc.value
      job.crewLimit = this.g.crewLimit.value
      for(var user in this.userService.userStore){
        var element = <HTMLInputElement> document.getElementById("customCheck"+user);
        if(element.checked){
            users.push(user)
        } 
      }
      job.offeredCrew= users
      console.log(job.offeredCrew)
      job.date = this.g.dateForm.value
      console.log(job.date)
      if(this.time5){
      job.startTime = this.time5.hour +":"+ this.time5.minute
      }
      if(this.time6){
      job.endTime = this.time6.hour +":"+ this.time6.minute
      }
      this.userService.newJob(job)
      console.log("CREATING JOB")
    }

    onItemSelect(item: any) {
      console.log(item);
      this.getUserList()
      
    }

    onItemDeselect(item: any) {
      console.log(item);
      this.getUserList()
      
    }
    onSelectAll(items: any) {
      console.log(items);
      this.getUserList()
    }

    onDeselectAll(items: any) {
      console.log(items);
      this.getUserList()
    }

    acceptJob(job){
        this.userService.acceptJob(job)
    }

    declineJob(job){
      console.log("declining job")
      this.userService.declineJob(job)
      
  }

    submitHours(job){
      alert("Implement this please")
    }

    // onDateSelection(data){
    //   console.log(data)
    //   this.date=console.log(data)
    // }

    get f() { return this.newJob.controls; } //helper function
    createJob(){
      var users =[]
      var job:Job = new Job();
      job.jobName = this.f.jobName.value
      job.desc = this.f.jobDesc.value
      job.crewLimit = this.f.crewLimit.value
      for(var i = 0; i<this.userList.length;i++){
        var element = <HTMLInputElement> document.getElementById("customCheck"+i);
        if(element.checked){
            users.push(this.userList[i])
        } 
      }
      job.offeredCrew= users
      console.log(job.offeredCrew)
      job.date = this.f.dateForm.value
      console.log(job.date)
      if(this.time){
      job.startTime = this.time.hour +":"+ this.time.minute
      }
      if(this.time2){
      job.endTime = this.time2.hour +":"+ this.time2.minute
      }
      console.log(job.startTime)
      console.log(job)
      this.userService.newJob(job)
      console.log("CREATING JOB")
    }
  }


