export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyCH-yHec9swKAZ__F-ql-F3DCHI6jLIBt8",
        authDomain: "staffing-4d52d.firebaseapp.com",
        databaseURL: "https://staffing-4d52d.firebaseio.com",
        projectId: "staffing-4d52d",
        storageBucket: "staffing-4d52d.appspot.com",
        messagingSenderId: "914840807777"
      }
  };

