import { Component } from '@angular/core';
import { AuthGuard } from './_guards';

@Component({
    selector: 'app',
    templateUrl: 'app.component.html'
})

export class AppComponent {
    loggedout;
    constructor(public AuthG: AuthGuard){
        this.loggedout = this.AuthG.logged;
        console.log(this.loggedout)
    }

 }