import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators ,FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserService } from '../_services';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  registerForm: FormGroup;
  constructor(private formBuilder: FormBuilder,private userService: UserService) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      groupName: ['', Validators.required],
      groupDesc: ['']
  });
  }

  submitted(){
    
  }

}
