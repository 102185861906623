import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services';
import { User, Group, Job } from '../_models';
// import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap'
import { FormBuilder, FormGroup, Validators ,FormsModule, ReactiveFormsModule } from '@angular/forms';
@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})


export class JobsComponent implements OnInit {

  dropdownList = [];
  selectedItems = [];
  userList = [];
  dropdownSettings = {};
  time; 
  time2; 
  spinners = false;
  newJob: FormGroup;
  date;
  today;
  constructor(public userService: UserService,private formBuilder: FormBuilder) {
    this.today=new Date();
    
  }

  

  ngOnInit() 
    {
      this.newJob = this.formBuilder.group({
        jobName: ['', Validators.required],
        jobDesc: [''],
        crewLimit: [1],
        dateForm:[]

      });

      
      console.log(this.dropdownList)
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: false,
        enableCheckAll: false
      };
    }

    
    getUserList(){
      var parentThis = this
      // console.log(this.selectedItems)
      this.userList=[]
      for(var i in this.selectedItems){
        console.log(this.selectedItems[i].item_id-1)
        this.userList= this.userList.concat(this.userService.groupStore[this.userService.currentUser.groups[this.selectedItems[i].item_id-1]].members);   //[...new Set([...this.userList, ...this.userService.currentUser.groups)];
        this.userList = this.userList.filter(function (item, pos) {return parentThis.userList.indexOf(item) == pos});
      }
      // console.log(this.userList)
    }
    

    onItemSelect(item: any) {
      console.log(item);
      this.getUserList()
      
    }

    onItemDeselect(item: any) {
      console.log(item);
      this.getUserList()
      
    }
    onSelectAll(items: any) {
      console.log(items);
      this.getUserList()
    }

    onDeselectAll(items: any) {
      console.log(items);
      this.getUserList()
    }

    acceptJob(job){
        this.userService.acceptJob(job)
    }

    declineJob(job){
      console.log("declining job")
      this.userService.declineJob(job)
      
  }

    submitHours(job){
      alert("Implement this please")
    }

    // onDateSelection(data){
    //   console.log(data)
    //   this.date=console.log(data)
    // }

    get f() { return this.newJob.controls; } //helper function
    createJob(){
      var users =[]
      var job:Job = new Job();
      job.jobName = this.f.jobName.value
      job.desc = this.f.jobDesc.value
      job.crewLimit = this.f.crewLimit.value
      for(var i = 0; i<this.userList.length;i++){
        var element = <HTMLInputElement> document.getElementById("customCheck"+i);
        if(element.checked){
            users.push(this.userList[i])
        } 
      }
      job.offeredCrew= users
      console.log(job.offeredCrew)
      job.date = this.f.dateForm.value
      console.log(job.date)
      if(this.time){
      job.startTime = this.time.hour +":"+ this.time.minute
      }
      if(this.time2){
      job.endTime = this.time2.hour +":"+ this.time2.minute
      }
      console.log(job.startTime)
      console.log(job)
      this.userService.newJob(job)
      console.log("CREATING JOB")
    }
  }


