import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore'
import { auth } from 'firebase/app';
import { UserService } from "./user.service"


@Injectable()
export class AuthenticationService {

    constructor(public afAuth: AngularFireAuth, public afs: AngularFirestore, public userService: UserService) {
        var parentThis = this;
        afAuth.auth.setPersistence("local").then(function (res) {
            console.log(res)
        })
    }



    logout() {
        console.log("logout")
        this.afAuth.auth.signOut();
        // this.afs.collection('posts').doc('my-custom-id').set({ 'title': "jon", 'content': "jon" });
    }


    resetPassword(){
        this.afAuth.auth.sendPasswordResetEmail(this.userService.currentUser.email).then(function() {
            return true;
          }).catch(function(error) {
            return false;
          });
    }


    login(email: string, password: string) {
        var parentThis = this;
        return this.afAuth.auth.setPersistence("local").then(function () {
            return parentThis.afAuth.auth.signInWithEmailAndPassword(email, password).then(function (user) {
                // console.log(user)
                if (user && user.user.uid) {
                    localStorage.removeItem("user");
                    localStorage.setItem("user", parentThis.afAuth.auth.currentUser.uid)
                    parentThis.userService.setUserID(parentThis.afAuth.auth.currentUser.uid);
                    
                    // localStorage.setItem('currentUser', JSON.stringify(user));
                    return ["user", user];
                }
            }, function (error) {
                return ["error", error];
            })


        }, function (error) {
            return ["error", error];
        })


    }

    register(user) {
        console.log(user)
        // var displayName = user.firstName
        var parentThis = this
        return this.afAuth.auth.createUserWithEmailAndPassword(user.email, user.password).then(function (response) {
            // parentThis.pushUserData(response, user, parentThis)
            var userRef = parentThis.afs.collection('user').doc(response.user.uid);
            var emailRef = parentThis.afs.collection('emails').doc(response.user.email);
            parentThis.login(response.user.email, user.password)
            const data = {
                uid: response.user.uid,
                email: response.user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                groups: [],
                jobs:[],
                jobsAdmin:[]
            };
            userRef.set(data).then(function () {
                console.log("Document successfully written!");
            })
                .catch(function (error) {
                    console.error("Error writing document: ", error);
                });
            emailRef.set({uid:response.user.uid}).then(function () {
                console.log("Document successfully written!");
            })
                .catch(function (error) {
                    console.error("Error writing document: ", error);
                });

            return ["user", response];
        }, function (error) {

            return ["error", error];
        })
    }


}