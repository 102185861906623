import { NgModule }      from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule }    from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AngularFireModule } from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import { environment } from '../environments/environment';

// used to create fake backend
import { fakeBackendProvider } from './_helpers';

import { AppComponent }  from './app.component';
import { routing }        from './app.routing';

import { AlertComponent } from './_directives';
import { AuthGuard } from './_guards';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AlertService, AuthenticationService, UserService } from './_services';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { enableProdMode } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GroupsComponent } from './groups/groups.component';
import { NavbarComponent } from './navbar/navbar.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import * as $ from 'jquery';
import { JobsComponent } from './jobs/jobs.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import { multiSelect} from 'angular-bootstrap-multiselect'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AdminComponent } from './admin/admin.component';

enableProdMode()

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        routing,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFirestoreModule.enablePersistence({experimentalTabSynchronization:true}),
        // multiSelect,
        NgbModule,
        NgMultiSelectDropDownModule.forRoot()
        
        // FontAwesomeModule
    ],
    declarations: [
        AppComponent,
        AlertComponent,
        HomeComponent,
        LoginComponent,
        RegisterComponent,
        GroupsComponent,
        NavbarComponent,
        UserProfileComponent,
        JobsComponent,
        AdminComponent,

    ],
    providers: [
        AuthGuard,
        AlertService,
        AuthenticationService,
        UserService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

        // provider used to create fake backend
        fakeBackendProvider
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }