export class User {
    uid: string;
    email: string;
    firstName: string;
    lastName: string;
    groups: string[];
    jobs: string[];
    jobsAdmin: string[];
    // constructor(uid,email,firstName,lastName,groups){
    //     // this.uid=uid;
    //     // this.email=email
    //     // this.firstName=firstName
    //     // this.lastName=lastName
    //     // this.groups=groups
    // }
}